.pages-faq{
  margin: 5rem auto;
  width: calc(min(90vw, 30rem));
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width:550px) {
    width: calc(min(90vw, 60rem));
  }

  .header{
    margin: 2rem 0;
  }

  .collapse-list {
    width: 100%;

    .list-item {
      border-top :  0.1rem solid lighten($neutral1, 20%);
      padding: 0.5rem;
      cursor: pointer;
    
      &:last-child{
        border-bottom :  0.1rem solid lighten($neutral1, 20%);
      }

      &__preview{
        display: grid;
        grid-template-columns: 90% 10%;
        padding: 1rem 0;

        i{
          text-align: center;
        }
      }

      &__title{
        margin-right: 1rem;
        font-size: 1.6rem;
      }

      &__content{
        padding: 1rem 0;
        max-width: 90%;
      }
    }

    .list-item--active .list-item{
      &__title{
        color: lighten($color3, 20%);
      }

      &__content{
        display: block;
      }
    }

    .list-item--inactive .list-item{
      &__title{
        color: $color4;
      }

      &__content{
        display: none;
      }
    }
  }

  details > summary {
    list-style-type: none;
}
}