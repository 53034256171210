$color1: #EDEDED;
$color2: #F7DDD9;
$color3: #4F6C6B;
$color4: #1E292B;
$accent1: adjust-color($color2, $lightness:-40%, $saturation:-20%);
$neutral1: lighten(black, 50%);
$subtext: lighten($color4, 20%);
$color-number: saturate($color3, 20%);
$color-bg: #FFFFFF;



