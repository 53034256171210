.autocomplete{
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;

  &__input{
    display: flex;
    flex-direction: column;
  }

  &__field {
    display:inline-block;
    padding: 0.5rem 0.5rem;
    line-height: 2rem;
    border: 0.1rem solid lighten($neutral1, 20%);
    border-radius: 0.2rem;
    font-family: 'Nunito', sans-serif;
    font-size: 1.6rem;

    &--default{
      border: 0.1rem solid lighten($neutral1, 20%);
    }

    &--error{
      border: 0.1rem solid $accent1;
    }
  }

  &__option-list{
    z-index: 1;
    position: absolute;
    width: 100%;
    border: 0.1rem solid lighten($neutral1, 20%);
    border-radius: 0.2rem;
    cursor: pointer;
    background-color: $color-bg;
  }

  &__option-item{
    padding: 0.5rem;
  }
}