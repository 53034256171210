//[1] https://css-tricks.com/preventing-a-grid-blowout/
//[2] https://stackoverflow.com/questions/70961650/content-overflowing-from-css-grid

// READ: https://cloudfour.com/thinks/the-ems-have-it-proportional-media-queries-ftw/

.inventory {
  display:flex;
  flex-direction: column;
  height: 100%;
  margin: 2rem 0;

  &__results{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); // prevent grid blowout [1] [2]
    grid-gap: 1rem;
  
    @media(min-width:600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  
    @media(min-width:1200px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__card{
    cursor: pointer;
    text-decoration: none;
  }
  
  &__image{
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 0.5rem;
    background-color: lightgray;
  }

  &__label{
    margin: 1rem 0rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2rem;
    // resize: horizontal;
  }

  &__price{
    font-family: 'Fenix', serif;
    font-size: 2rem;
    color: $color-number;
  }

  &__empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 20rem;
    height: 100%;
  }

  &__empty-msg1{
    margin: 1rem 0;
  }

  &__loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 20rem;
    height: 100%;
  }

  &__loader {
    width: 48px;
    height: 48px;
    border: 5px solid $color2;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  &__loading-msg1{
    margin: 1rem 0;
  }

}