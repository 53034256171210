.list-modal{
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */


  &--visible {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &--invisible {
    display: none;
  }

  &__content {
    width: calc(min(90%, 30rem));
    margin: 2rem auto;
    display : flex;
    flex-direction: column;

    &--withFX {
      animation-name: slide-in-bottom;
      animation-duration: 0.4s; 
    }

    &--noFX{
      animation-name:none;
      animation-duration: none;
    }
  }

  &__body{
    background-color: #fefefe;
    border-radius: 0.5rem;
    overflow-y: auto;
    max-height: calc(80vh - 8rem);
    margin: 1rem 0;

    @media only screen and (min-height:550px) {
      height: auto;
    }
    
  }

  &__footer {
    background-color: #fefefe;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem;
    text-align: center;
  }
  
  &__title{
    padding: 1.5rem 1rem;
    border-bottom: 0.1rem solid $neutral1;
  }

  ul{
    margin: 1rem 0rem;
  }

  li{
    margin: 0rem 2rem;
    padding: 1.5rem 1rem;
    border-bottom: 0.1rem solid lighten($neutral1, 30%);  

    &:last-child{
      border-bottom: none;
    }
  }

  &__link--active{
    text-decoration: none;
    color: $accent1;
    font-weight: bold;

    &:hover{
      color: $accent1;
    }

    i {
      color: $accent1;
      margin-left: 1rem;
      display : inline;
    }
  }

  &__link--inactive{
    text-decoration: none;
    color: $color4;

    &:hover{
      color: lighten($color3, 20%);
    }

    i{
      display:none;
    }
  }

  &__checkbox{
    @extend %list-btn;
    
    &--active{
      color : $accent1;
    }
  }
}