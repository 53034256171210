.coverline {
  margin: 5rem calc(min(3.5vw, 5rem));
  font-size: calc(min(9vw, 3.2rem));

  @media(min-width:400px) {
    margin: 5rem auto;
    max-width: 40rem;
  }
  
  @media(min-width:750px) {
    margin: 5rem auto;
    width: calc(min(90%, 100rem));
    max-width: none;
  }

  &__header{
    margin: 2rem 0;
    font-size: 1em;

    @media(min-width:400px) {
     text-align: center;
    }

    @media(min-width:750px) {
      text-align: left;
     }
  }

  &__list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;

    @media(min-width:750px) {
      grid: auto-flow / repeat(4, 1fr);
    }
  }

  &__item{
    position: relative; // for absolute positioning to work for labels
    cursor: pointer;
  }

  &__label{
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 0%);
    background-color: lighten($color1, 5%);
    width: 100%;
    padding: 1rem;
    border-radius: 0 0 1rem 1rem; 
    box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);

    @media(min-width:400px) {
      transform: translate(-50%, -50%);
      border-radius: 2rem; 
      width: auto;
      padding: 1rem 2rem;
    }

    & > h2{
      font-size: 0.625em;
      text-align: center;
      color: darken($color3, 20%);
    }
  }

  &__image{
    width: 100%;
    height: auto;
    border-radius: 1rem;
    border: 0.1rem solid lighten($color1, 5%);
    background-color: lightgray;
  }
}