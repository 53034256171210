.pages-checkout{
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  flex-direction: column;
  width: calc(min(100vw, 360px));

  @media only screen and (min-width:600px) {
    margin: 5rem auto;
    width: calc(min(90vw, 100rem));
    flex-direction: row;
    align-items: flex-start;
  }

  .cart-summary {
    margin: 0 1rem 4rem;
    padding: 2rem;
    border: 0.1rem solid $color1;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);

    @media only screen and (min-width:600px) {
      width: calc(min(30vw, 35rem));
      margin: 0 2rem;
    }
  

    &__header{
      display : flex;
      align-items: baseline;
      padding-bottom: 1rem;
      border-bottom : 0.2rem solid $neutral1;  
    }

    &__cart-count{
      margin: 0rem 1rem;
    }

    &__body{
      margin: 2rem 5%;
      
    }

    &__list{
      font-weight: 700;
      > div {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0rem;
      }

      > div:last-child{
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid lighten($neutral1, 30%);
      }
    }

    &__price{
      font-family: 'Fenix', serif;
      font-size: 2rem;
      color: $color-number;
      font-weight: 400;
    }

    &__delivery-info{
      color : $accent1;
      font-weight: 400;
    }

    &__total{
      display : flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0rem;
    }

    &__total-price{
      font-family: 'Fenix', serif;
      font-size: 3rem;
      color: $color-number;
      font-weight: 400;
    }
  }

  .billing{
    display: flex;
    flex-direction: column;
    margin: 0 1rem;

    &__header{
      padding-bottom : 1rem;
    }

    &__subheader {
      padding: 1rem 0;
      font-size: 1.6rem;
    }

    &__form{
      @media only screen and (min-width:600px) {
        width: calc(min(40vw, 40rem));
      }
    }

    &__full{
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &__group{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      
      label {
        margin: 1rem 0;
        font-weight: 700;
      }
    }

    &__field{
      display:inline-block;
      padding: 0.5rem 0.5rem;
      line-height: 2rem;
      margin-bottom: 1rem ;
      border-radius: 0.2rem;
      font-family: 'Nunito', sans-serif;
      font-size: 1.6rem;


      &--default{
        border: 0.1rem solid lighten($neutral1, 20%);
      }

      &--error{
        border: 0.1rem solid $accent1;
      }
    }

    &__field-error-info{
      color : $accent1;
      margin-bottom: 2rem;
    }

    &__footer {
      display: flex;
    }

    &__button {
      @include textbutton;
      margin : 2rem 0rem;
    }

    .preview {
      border-bottom: 0.1rem solid lighten($neutral1, 30%);
      border-radius: 0.2rem;

      &__header {
        display: flex;
        align-items: center;

        h3{
          font-size: 1.6rem;
        }
      }

      
      &__info{
        margin: 1rem 0rem;
        display: flex;
        align-items: center;

        i{
          color : $color3;
          margin-right: 1rem;
        }
      }

      &__info-label{
        font-weight: 700;
      }

      &__info-body{
        color: $neutral1;
      }

      &__edit{
        @extend %icon-btn;
        width: 2.4rem;
        height: 2.4rem;
        color: $color-bg;

        &--enabled{
          background-color: $accent1;
          cursor: none;

          &:active{
            background-color: darken($color-bg, 10%);
          }

        }

        &--disabled{
          background-color: $neutral1;
          cursor: none;

          &:active{
            background-color: $neutral1;
          }
        }
      }
    }

    .payment {
      margin: 1rem 0rem;

      &__header {
        margin: 1rem 0rem;

        h3 {
          font-size: 1.6rem;
        }
      }

      &__desc {
        margin: 1rem 0rem;
      }

      &__footer {
        display : flex;
        flex-direction: column;
        margin: 2rem 0rem;

        .loader {
          margin : 0 auto;
          
        }
      }

      &__btn {
        @include textbutton;
        align-self: flex-start;
      }
    }


  }

}