.footer {
  margin: 5rem 2rem 1rem;

  &__list {
    @media(min-width:400px) {
      margin: 0 auto;
      max-width: 40rem;
    }
    
    @media(min-width:750px) {
      display: grid;
      grid: auto / 3fr 1fr 1fr;
      grid-gap: 0 5rem;
      margin: 0 auto;
      width: 90%;
      max-width: 100rem;
    }  
  }

  &__item{
    margin: 2rem auto;
  }

  &__header{
    margin: 1rem auto;
    white-space: nowrap;
    font-size: 2rem;
  }

  &__content{
    @media(min-width:750px) {
      width: 80%;
    }  

    ul {
      display: grid;
      grid: 1fr 1fr/ 1fr 1fr;

      @media(min-width:750px) {
        display: block;
        grid-gap: 0 5rem;
      }  
    }
    
    li {
      line-height: 3rem;
    }

    a{
      text-decoration: none;
      color: $color4;

      &:hover{
        color: lighten($color3, 20%);
      }
    }
  }

  &__line{
    height: 0.2rem;
    background-color: $color1;
    border: none;
  }

  &__end{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__socials{
    display: flex;
    justify-content: end;
  }

  &__button{
    @extend %icon-btn;
    margin: 0rem;
    & > i{
      font-size: 3.5rem;
    }
  }
}