@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'); //Headers
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap'); //Body
@import url('https://fonts.googleapis.com/css2?family=Stoke:wght@300&display=swap'); // Logo
@import url('https://fonts.googleapis.com/css2?family=Fenix&display=swap'); // Numbers
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"); //Icons

html {
  font-size: 62.5%;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 1.6rem;
  color: $color4;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight : 700;
  color: $color4;
  line-height: 1;
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.4rem;
}
h3 {
  font-size: 2rem;
}

button {
  cursor: pointer;
}

%no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus{
    outline: none !important;
  }
}

%text-btn {
  font-weight: 400;
  font-size: large;
  background-color: $color3;
  text-transform: uppercase;
  color: $color1;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;

  &:active{
    background-color: darken($color3, 10%);
  }
}

%icon-btn {
  @extend %no-select;
  height: 5rem;
  width: 5rem;
  border : 0rem;
  margin: 0.5rem;
  background-color: $color-bg;
  color: $color4;
  border-radius: 100%;
  padding: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  
  &:active{
    background-color: darken($color-bg, 10%);
  }
}

%list-btn{
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
  font-size: 1.6rem;
  color: $color4;
  border: none;
  background: none;
  display: inline-block;
  text-decoration: none;

  img { 
    width: 10rem;
  }

  i {
    padding-right: 1rem;
  }
}

@mixin textbutton($color: $accent1) {
  font-weight: 400;
  font-size: large;
  background-color: $color;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;

  &:active{
    background-color: lighten($color, 10%);
  }
}

%text-btn2 {
  @extend %no-select;

  font-size: 1.6rem;
  background-color: $color-bg;
  color: $color4;
  border-radius: 0.30em;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border : 0.1rem solid lighten($color4, 50%);

  span {
    margin : 0.2em;
  }
}