.pages-thanks{
  margin : 5rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .thanks{
    padding: 5rem 5rem;
    width: calc(min(80vw, 80rem));
    border : 0.3rem dashed lighten($accent1, 15%);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      width: 10rem;
      height: auto;
      color: $color2;
      margin: 1rem 0;
    }
    
    h1{
      text-align: center;
      color: $color4;
      font-family: 'Stoke', serif;
    }

    div{
      text-align: center;
      color: $color3;
    }

    &__detail{
      margin: 2rem 0rem;
    }

    &__home-btn{
      @include textbutton;
    }
  }
}