.product {
  margin: 5rem auto;
  width: calc(min(90vw, 30rem));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  

  @media only screen and (min-width:550px) {
    width: calc(min(90vw, 80rem));
  }

  &__nav{
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    &-button{
      margin-right: 1rem;
      @extend %text-btn2;
    }
  }

  &__body{
    display: flex;
    flex-direction: column;
    
    @media only screen and (min-width:550px) {
      flex-direction: row;
    }
  }
  
  &__preview {
    min-width: 50%;
    margin-right: 0;

    @media only screen and (min-width:550px) {
      margin-right: 2rem;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: auto;
      border-radius: 0.5rem;
    }
  }

  &__details {
    display: flex;
    flex-flow: column;
    margin-top: 2rem;

    @media only screen and (min-width:550px) {
      margin-top: auto;
    }
  }

  &__price{
    margin: 1.5rem 0rem;
    font-family: 'Fenix', serif;
    font-size: 2.8rem;
    color: $color-number;
  }

  &__cart{
    display : flex;
  }

  &__cart-btn{
    @include textbutton;
    margin-left: 2rem;
  }

  &__quantity{
    display: inline;
  }

  &__desc{
    margin: 2rem 0rem;

    h2 {
      margin: 1rem 0rem;
    }

    p {
      white-space: pre-wrap;
    }
  }

  .care-list{
    margin: 2rem 0rem;

    &__item{
      margin: 1.5rem 0rem;
    }
    
    &__type-header{
      margin: 1rem 0rem;
      font-size: 1.6rem;
    }

    &__icon-group{
      margin : 0rem 1rem 0rem 1rem;
      color : $color3;
    }

    &__type-desc{
      font-size: 1.6rem;
      color : black;
    }
  }
  
}