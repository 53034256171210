.loader {
  &__spinner{
    width: 48px;
    height: 48px;
    border: 5px solid $color2;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}