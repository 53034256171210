// The current problem is that for mobile a modal should appear
// But the filters should show up in mobile because of higher DPI
// meaning 200 reso in pc will look quite small in a mobile's 200 reso
// meaning it would be hard to push buttons if they're very small (but large in pc)
// we could check items with dpr higher than 2 to completely remove...
// we made the decision to use a poitner check ebcause it's current tech doesnt
// account for physical screen size

.pages-shop{
  margin: 5rem 1rem;
  display: flex;

  @media only screen and (min-width:700px) {
    margin: 2rem auto;
    width: calc(min(80%, 100rem));
  }

  .filters{
    display: none;
    margin-right: 5rem;

    @media(min-width:900px) {
      display: block;
      width: 20rem;
      margin-right: none;
    }
  }
  

  .filters{
    li {
      line-height: 3rem;
    }

    &__header{
      padding: 1rem 0;
      color : $color3;
    }

    &__categories{
      padding-top: 1rem;

      i {
        display:none;
      }
    }

    &__link--active{
      text-decoration: none;
      color: $accent1;
      font-weight: bold;

      &:hover{
        color: lighten($accent1, 20%);
      }

      i {
        color: $accent1;
        margin-left: 1rem;
        display : inline;
      }
    }

    &__link--inactive{
      text-decoration: none;
      color: $color4;

      &:hover{
        color: lighten($color3, 20%);
      }
    }

    &__option{
      @extend %list-btn;
      
      &.filters--active{
        color : $accent1;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__line{
      margin: 1rem 0;
      width: 100%;
      height: 0.2rem;
      background-color: lighten($neutral1, 30%);
    }
    
    .control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      &__sort{
        display: none;

        @media(min-width:900px) {
          display: flex;
        }
      }
    }

    .compact{
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__left{
        display: flex;
      }

      &__button{
        margin-right: 1rem;
        @extend %text-btn2;
      }

      &__sort-btn{
        @extend %icon-btn;
        font-size: 2.6rem;
        margin: 0;
      }

      @media(min-width:900px) {
        display: none;
      }
    }
  }
}