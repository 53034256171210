.pages-cart {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  flex-direction: column;
  width: calc(min(100vw, 360px));

  @media only screen and (min-width:600px) {
    margin: 5rem auto;
    width: calc(min(90vw, 100rem));
    flex-direction: row;
    
  }

  .main{

    &--default{
      @media only screen and (min-width:600px) {
        width: calc(min(50%, 35rem));
      }
    }
    
  }

  .title {
    @media only screen and (min-width:600px) {
      display: flex;
    }

    &__label {
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (min-width:600px) {
        justify-content: flex-start;
        align-items: center;
      }
    }

    &__count {
      margin: 2rem 1rem;
      color: $subtext;
      text-align: center;

      @media only screen and (min-width:600px) {
        text-align: left;
        margin: 1rem 2rem;
      }
    }
  }

  .list {
    display : flex;
    flex-flow: column;
    margin: 1.5rem 0rem;
    
    &__row{
      border-bottom: solid 0.2rem lighten(black, 90%);
      padding: 0rem 1rem 3rem;

      &:first-child{
        border-top: solid 0.2rem lighten(black, 90%);
      }
    }

    &__header{
      display: flex;
      justify-content: flex-end;

      button {
        @extend %icon-btn;
        background-color: lighten($neutral1, 10%);
        color: white;
        width: 3rem;
        height: 3rem;
        
        &:hover{
          background-color: lighten($color3, 20%);
        }
      }
    }

    &__content{
      display: flex;
    }

    &__preview{
      display: flex;

      a {
        display: flex;
      }
      
      img {
        width: calc(min(100px, 30vw));
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }

    &__detail{
      margin-left: 2rem;
    }


    &__product-label{
      margin: 1rem 0rem 2rem;

      h2 {
        font-size: 2rem;
      }
    }

    &__quantity-control{
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
    }


    &__subtotal {
      margin: 0rem 0.5rem;
      font-family: 'Fenix', serif;
      font-size: 2.4rem;
      color: $color-number;
    }
  }

  .empty-list{
    margin: 1rem;
    padding: 5rem calc(min(15rem, 15vw));
    border : 0.3rem dashed lighten($accent1, 15%);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img{
      width: 10rem;
      color: $color2;
      margin: 1rem 0;
      height: auto;
    }
    
    h1{
      text-align: center;
      color: $color4;
      font-family: 'Stoke', serif;
    }

    >div{
      text-align: center;
      color: $color3;
    }

    &__detail{
      margin: 2rem 0rem;
    }

    &__shop-btn{
      @include textbutton;
    }
  }

  .subtotals{
    @media only screen and (min-width:600px) {
      align-self: flex-start;
    }
  }
}