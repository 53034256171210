
.header {
  margin: 1rem 1rem;

  .first{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.2rem solid $color1;
  }

  .second{
    @media only screen and (min-width:600px) {
      display: none;
    }
  }

  .logo {
    display:flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $color4;
    font-size: calc(50vw/4); // 50% of viewport/4

    &__icon {
      max-height: 1.4em;
      max-width: 1.4em;
    }
  
    &__text {
      font-family: 'Stoke', serif;
      font-weight: 400;
      margin: 1rem;
      letter-spacing: -0.2rem;
      font-kerning: normal;
    }

    @media only screen and (min-width:350px) {
      font-size: 4.5rem;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .search {
      display: none;
      @media only screen and (min-width:600px) {
        display: flex;
      }
    }

    &__cart{
      @extend %icon-btn;
    }
  }

  .search {
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    background-color: lighten($color1, 5%);
    border: 0.2rem solid darken($color1, 10%);
    margin: 1rem 0;

    &:focus-within{
      border: 0.2rem solid $color4;
    }

    &__field{
      background-color: lighten($color1, 5%);
      flex: 1;
      margin: auto;
      font-size: 1.6rem;
      border-radius: 0.5rem;
      border: none;
      padding: 1rem;
      color: darken($color1, 50%);

      &:focus{
        outline:none;
      }
    }

    &__button{
      @extend %icon-btn;
      background-color: lighten($color1, 5%);
      align-self: flex-end;

      &:hover{
        background-color: darken($color1, 10%);
      }
    }
  }
}


// .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 0.2rem solid $color1;

//   .logo {
//     display:flex;
//     align-items: center;
//     margin-left: 5rem;
//     cursor: pointer;
//     text-decoration: none;
//     color: $color4;

//     &__icon {
//       max-height: 6.4rem;
//       max-width: 6.4rem;
//     }
  
//     &__text {
//       font-size: 4.5rem;
//       font-family: 'Stoke', serif;
//       font-weight: 400;
//       margin: 1rem;
//       letter-spacing: -0.2rem;
//       font-kerning: normal;
//     }
//   }

//   .actions {
//     display: flex;
//     margin-right: 5rem;
//     align-items: center;

//     .search {
//       border-radius: 0.5rem;
//       width: 50rem;
//       display: flex;
//       justify-content: space-between;
//       background-color: $color1;
//       border: 0.2rem solid darken($color1, 10%);
//       margin: 1rem;
  
//       &:focus-within{
//         border: 0.2rem solid $color4;
//       }
  
//       &__field{
//         background-color: $color1;
//         flex: 1;
//         margin: auto;
//         font-size: 1.6rem;
//         border-radius: 0.5rem;
//         border: none;
//         padding: 1rem;
//         color: darken($color1, 50%);
  
//         &:focus{
//           outline:none;
//         }
//       }
  
//       &__button{
//         @extend %icon-btn;
//         background-color: $color1;
//         align-self: end;
  
//         &:hover{
//           background-color: darken($color1, 10%);
//         }
//       }
//     }

//     &__cart{
//       @extend %icon-btn;
//     }
//   }
// }