.quantity-control {
  display: flex;
  align-items: center;

  &__btn {
    @extend %icon-btn;
    border : 0.2rem solid lighten(black, 60%);
    color : lighten(black, 60%);
    height : 3rem;
    width : 3rem;

    &--inactive {
      border : 0.2rem solid lighten(black, 80%);
      color : lighten(black, 80%);
      cursor : default;
      pointer-events: none;
    }
  }

  &__value {
    font-family: 'Fenix', serif;
    font-size: 2.4rem;
    color: saturate($color3, 20%);
    border : 0;
    width: 4rem;
    text-align: center;
  }
}