@keyframes slide-in-bottom {
  from {
    transform:translateY(100%);
  }

  to {
    transform: translateY(0);
  };
}

@keyframes slide-out-bottom {
  from {
    transform: translate(0, 0);
  }

  to {transform: translate(0, 100%)};
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}