.subtotals{
  margin: 1rem 2rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 0.1rem solid $color1;
  border-radius: 0.51rem;
  box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);

  @media only screen and (min-width:600px) {
    margin: 0 2rem;
  }

  &__header {
    margin: 1rem 0;
    padding-bottom: 1rem;
  }

  &__info {
    display : flex;
    margin: 2rem 0;
    justify-content: space-between;
  }

  &__price {
    font-family: 'Fenix', serif;
    font-size: 2.4rem;
    color: $color-number;
  }

  &__button {
    @include textbutton;
    margin: 1rem 0;
  }

  &__delivery {
    margin: 1rem 0;
    color: $subtext;
    font-size: 1.6rem;
  }
}