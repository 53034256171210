.radioset{
  &__option{
    @extend %list-btn;

    &--active {
      color: $color4;
      cursor: pointer;
    }

    &--inactive {
      color: $neutral1;
      cursor: default;
    }
  }
}