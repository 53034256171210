.modal{
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  &--visible {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media only screen and (min-width:550px) {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  &--invisible {
    display: none;
  }

  &__content {
    margin: 1rem auto;
    width: calc(min(90%, 30rem));
    background-color: #fefefe;
    border-radius: 0.5rem;
    padding: 1rem;

    @media only screen and (min-width:550px) {
      margin: 1rem 1rem;
    }

    &--withFX{
      animation-name: slide-in-bottom;
      animation-duration: 0.4s; 
    }

    &--noFX{
      animation-name: none;
      animation-duration: none;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__icon-btn{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background-color: lighten($neutral1, 10%);
    color: white;
    padding: 0rem;
    border : 0rem;
  }

  &__body {
    display: flex;
    margin: 0rem 1rem;
  }

  &__check-icon{
    position: absolute;
    transform: translate(-50%, -50%);
    color: saturate($color3, 20%);
  }

  &__image {
    width: 8rem;

    img {
      border-radius: 0.5rem;
      width : 100%;
      height: auto;
      object-fit: contain;
    }  
  }

  &__detail  {
    margin: 0rem 1.5rem;
  }

  &__product-header {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &__product-price {
    font-family: 'Fenix', serif;
    font-size: 2rem;
    color: saturate($color3, 20%);
    margin: 0.5rem 0rem;
  }

  &__footer {
    margin: 1rem;
    display: flex;
    justify-content: center;
  }

  &__view-cart-btn{
    @include textbutton;
    font-size: 1.6rem;
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
}