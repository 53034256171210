.pages-contact{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem auto;
  width: calc(min(100vw, 360px));

  @media only screen and (min-width:600px) {
    margin: 5rem auto;
    width: calc(min(90vw, 100rem));
    flex-direction: row;
    align-items: flex-start;
  }

  .page-info {
    margin: 0 1rem 4rem;
    padding: 2rem;
    border: 0.1rem solid $color1;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
    
    p {
      margin: 2rem auto; 
    }

    @media only screen and (min-width:600px) {
      width: calc(min(30vw, 35rem));
      margin: 0 2rem;
    }
  
  }

  .message-form{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 1rem;

    @media only screen and (min-width:600px) {
      width: calc(min(40vw, 40rem));
    }
    
    label {
      margin: 1rem 0;
      font-weight: 700;
    }

    &__field{
      padding: 0.5rem 0.5rem;
      line-height: 2rem;
      margin-bottom: 1rem ;
      border: 0.1rem solid lighten($neutral1, 20%);
      border-radius: 0.2rem;
      font-family: 'Nunito', sans-serif;
      font-size: 1.6rem;

      
      &--default{
        border: 0.1rem solid lighten($neutral1, 20%);
      }

      &--error{
        border: 0.1rem solid $accent1;
      }
    }

    &__field-error-info{
      color : $accent1;
      margin-bottom: 2rem;
    }

    &__textarea {
      padding: 0.5rem 0.5rem;
      line-height: 2rem;
      margin-bottom: 1rem ;
      border: 0.1rem solid lighten($neutral1, 20%);
      border-radius: 0.2rem;
      font-family: 'Nunito', sans-serif;
      font-size: 1.6rem;
      height: auto;

      &--default{
        border: 0.1rem solid lighten($neutral1, 20%);
      }

      &--error{
        border: 0.1rem solid $accent1;
      }
    }

    &__btn {
      @include textbutton;
      align-self: flex-start;
      margin: 2rem 0;
    }
  }
}