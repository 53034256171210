@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Stoke:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fenix&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");
@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out-bottom {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 100%);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
  color: #1E292B;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #1E292B;
  line-height: 1;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

button {
  cursor: pointer;
}

.product__nav-button, .pages-shop .content .compact__button, .pages-checkout .billing .preview__edit, .pages-cart .list__header button, .pages-shop .content .compact__sort-btn, .header .search__button, .header .actions__cart, .footer__button, .quantity-control__btn {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.product__nav-button:focus, .pages-shop .content .compact__button:focus, .pages-checkout .billing .preview__edit:focus, .pages-cart .list__header button:focus, .pages-shop .content .compact__sort-btn:focus, .header .search__button:focus, .header .actions__cart:focus, .footer__button:focus, .quantity-control__btn:focus {
  outline: none !important;
}

.pages-checkout .billing .preview__edit, .pages-cart .list__header button, .pages-shop .content .compact__sort-btn, .header .search__button, .header .actions__cart, .footer__button, .quantity-control__btn {
  height: 5rem;
  width: 5rem;
  border: 0rem;
  margin: 0.5rem;
  background-color: #FFFFFF;
  color: #1E292B;
  border-radius: 100%;
  padding: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.pages-checkout .billing .preview__edit:active, .pages-cart .list__header button:active, .pages-shop .content .compact__sort-btn:active, .header .search__button:active, .header .actions__cart:active, .footer__button:active, .quantity-control__btn:active {
  background-color: #e6e6e6;
}

.pages-shop .filters__option, .radioset__option, .list-modal__checkbox {
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
  color: #1E292B;
  border: none;
  background: none;
  display: inline-block;
  text-decoration: none;
}
.pages-shop .filters__option img, .radioset__option img, .list-modal__checkbox img {
  width: 10rem;
}
.pages-shop .filters__option i, .radioset__option i, .list-modal__checkbox i {
  padding-right: 1rem;
}

.product__nav-button, .pages-shop .content .compact__button {
  font-size: 1.6rem;
  background-color: #FFFFFF;
  color: #1E292B;
  border-radius: 0.3em;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 0.1rem solid #94afb4;
}
.product__nav-button span, .pages-shop .content .compact__button span {
  margin: 0.2em;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quantity-control {
  display: flex;
  align-items: center;
}
.quantity-control__btn {
  border: 0.2rem solid #999999;
  color: #999999;
  height: 3rem;
  width: 3rem;
}
.quantity-control__btn--inactive {
  border: 0.2rem solid #cccccc;
  color: #cccccc;
  cursor: default;
  pointer-events: none;
}
.quantity-control__value {
  font-family: "Fenix", serif;
  font-size: 2.4rem;
  color: #3c7f7c;
  border: 0;
  width: 4rem;
  text-align: center;
}

.autocomplete {
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
}
.autocomplete__input {
  display: flex;
  flex-direction: column;
}
.autocomplete__field {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  line-height: 2rem;
  border: 0.1rem solid #b3b3b3;
  border-radius: 0.2rem;
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
}
.autocomplete__field--default {
  border: 0.1rem solid #b3b3b3;
}
.autocomplete__field--error {
  border: 0.1rem solid #bb5949;
}
.autocomplete__option-list {
  z-index: 1;
  position: absolute;
  width: 100%;
  border: 0.1rem solid #b3b3b3;
  border-radius: 0.2rem;
  cursor: pointer;
  background-color: #FFFFFF;
}
.autocomplete__option-item {
  padding: 0.5rem;
}

.banner {
  display: flex;
  flex-direction: column;
  background-color: #EDEDED;
}
@media only screen and (min-width: 600px) {
  .banner {
    border-radius: 1rem;
    margin: 2rem auto;
    width: min(90%, 140rem);
    flex-direction: row-reverse;
  }
}
.banner .image {
  flex: 2;
}
.banner .image img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: lightgray;
}
@media only screen and (min-width: 600px) {
  .banner .image img {
    border-radius: 0 1rem 1rem 0;
  }
}
.banner .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5% 5%;
  flex: 1;
}
.banner .info__line {
  height: 0.5rem;
  width: 100%;
  color: #F7DDD9;
  background-color: #9db7b6;
  border: none;
  margin: 1rem 0;
}
.banner .info__text {
  margin: 1rem 0;
}
.banner .info__button {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  margin-top: 10%;
}
.banner .info__button:active {
  background-color: #c87b6f;
}

.coverline {
  margin: 5rem min(3.5vw, 5rem);
  font-size: min(9vw, 3.2rem);
}
@media (min-width: 400px) {
  .coverline {
    margin: 5rem auto;
    max-width: 40rem;
  }
}
@media (min-width: 750px) {
  .coverline {
    margin: 5rem auto;
    width: min(90%, 100rem);
    max-width: none;
  }
}
.coverline__header {
  margin: 2rem 0;
  font-size: 1em;
}
@media (min-width: 400px) {
  .coverline__header {
    text-align: center;
  }
}
@media (min-width: 750px) {
  .coverline__header {
    text-align: left;
  }
}
.coverline__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
}
@media (min-width: 750px) {
  .coverline__list {
    grid: auto-flow/repeat(4, 1fr);
  }
}
.coverline__item {
  position: relative;
  cursor: pointer;
}
.coverline__label {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0%);
  background-color: #fafafa;
  width: 100%;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
}
@media (min-width: 400px) {
  .coverline__label {
    transform: translate(-50%, -50%);
    border-radius: 2rem;
    width: auto;
    padding: 1rem 2rem;
  }
}
.coverline__label > h2 {
  font-size: 0.625em;
  text-align: center;
  color: #243131;
}
.coverline__image {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  border: 0.1rem solid #fafafa;
  background-color: lightgray;
}

.features {
  margin: 1rem 0;
  border: 0.1rem solid #EDEDED;
}
@media (min-width: 400px) {
  .features {
    margin: 5rem auto;
    max-width: 40rem;
    border-radius: 1rem;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 750px) {
  .features {
    margin: 5rem auto;
    width: min(90%, 100rem);
    max-width: 80rem;
  }
}
.features__list {
  display: grid;
  grid: repeat(2, 1fr)/repeat(2, 1fr);
  margin: 0 2rem;
  grid-gap: 1rem;
}
@media (min-width: 750px) {
  .features__list {
    grid: auto-flow/repeat(4, 1fr);
  }
}
.features__icon {
  display: block;
  margin: 10% auto;
  width: 60%;
  height: auto;
}
.features__label {
  text-align: center;
  white-space: pre-line;
  color: #1E292B;
  margin: 0 auto;
  font-size: 1.6rem;
}
.features__line {
  height: 0.3rem;
  color: #F7DDD9;
  background-color: #F7DDD9;
  border: none;
  width: 80%;
  margin: 1rem auto;
}
.features__desc {
  display: block;
  margin: 1rem auto;
  text-align: center;
  color: #486267;
  font-size: 1.4rem;
  padding: 5%;
}

.footer {
  margin: 5rem 2rem 1rem;
}
@media (min-width: 400px) {
  .footer__list {
    margin: 0 auto;
    max-width: 40rem;
  }
}
@media (min-width: 750px) {
  .footer__list {
    display: grid;
    grid: auto/3fr 1fr 1fr;
    grid-gap: 0 5rem;
    margin: 0 auto;
    width: 90%;
    max-width: 100rem;
  }
}
.footer__item {
  margin: 2rem auto;
}
.footer__header {
  margin: 1rem auto;
  white-space: nowrap;
  font-size: 2rem;
}
@media (min-width: 750px) {
  .footer__content {
    width: 80%;
  }
}
.footer__content ul {
  display: grid;
  grid: 1fr 1fr/1fr 1fr;
}
@media (min-width: 750px) {
  .footer__content ul {
    display: block;
    grid-gap: 0 5rem;
  }
}
.footer__content li {
  line-height: 3rem;
}
.footer__content a {
  text-decoration: none;
  color: #1E292B;
}
.footer__content a:hover {
  color: #7fa2a0;
}
.footer__line {
  height: 0.2rem;
  background-color: #EDEDED;
  border: none;
}
.footer__end {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__socials {
  display: flex;
  justify-content: end;
}
.footer__button {
  margin: 0rem;
}
.footer__button > i {
  font-size: 3.5rem;
}

.header {
  margin: 1rem 1rem;
}
.header .first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2rem solid #EDEDED;
}
@media only screen and (min-width: 600px) {
  .header .second {
    display: none;
  }
}
.header .logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #1E292B;
  font-size: 12.5vw;
}
.header .logo__icon {
  max-height: 1.4em;
  max-width: 1.4em;
}
.header .logo__text {
  font-family: "Stoke", serif;
  font-weight: 400;
  margin: 1rem;
  letter-spacing: -0.2rem;
  font-kerning: normal;
}
@media only screen and (min-width: 350px) {
  .header .logo {
    font-size: 4.5rem;
  }
}
.header .actions {
  display: flex;
  align-items: center;
}
.header .actions .search {
  display: none;
}
@media only screen and (min-width: 600px) {
  .header .actions .search {
    display: flex;
  }
}
.header .search {
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border: 0.2rem solid #d4d4d4;
  margin: 1rem 0;
}
.header .search:focus-within {
  border: 0.2rem solid #1E292B;
}
.header .search__field {
  background-color: #fafafa;
  flex: 1;
  margin: auto;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  border: none;
  padding: 1rem;
  color: #6e6e6e;
}
.header .search__field:focus {
  outline: none;
}
.header .search__button {
  background-color: #fafafa;
  align-self: flex-end;
}
.header .search__button:hover {
  background-color: #d4d4d4;
}

.inventory {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2rem 0;
}
.inventory__results {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
}
@media (min-width: 600px) {
  .inventory__results {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .inventory__results {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.inventory__card {
  cursor: pointer;
  text-decoration: none;
}
.inventory__image {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 0.5rem;
  background-color: lightgray;
}
.inventory__label {
  margin: 1rem 0rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2rem;
}
.inventory__price {
  font-family: "Fenix", serif;
  font-size: 2rem;
  color: #3c7f7c;
}
.inventory__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 20rem;
  height: 100%;
}
.inventory__empty-msg1 {
  margin: 1rem 0;
}
.inventory__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 20rem;
  height: 100%;
}
.inventory__loader {
  width: 48px;
  height: 48px;
  border: 5px solid #F7DDD9;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.inventory__loading-msg1 {
  margin: 1rem 0;
}

.list-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.list-modal--visible {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.list-modal--invisible {
  display: none;
}
.list-modal__content {
  width: min(90%, 30rem);
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
}
.list-modal__content--withFX {
  animation-name: slide-in-bottom;
  animation-duration: 0.4s;
}
.list-modal__content--noFX {
  animation-name: none;
  animation-duration: none;
}
.list-modal__body {
  background-color: #fefefe;
  border-radius: 0.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 8rem);
  margin: 1rem 0;
}
@media only screen and (min-height: 550px) {
  .list-modal__body {
    height: auto;
  }
}
.list-modal__footer {
  background-color: #fefefe;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  text-align: center;
}
.list-modal__title {
  padding: 1.5rem 1rem;
  border-bottom: 0.1rem solid gray;
}
.list-modal ul {
  margin: 1rem 0rem;
}
.list-modal li {
  margin: 0rem 2rem;
  padding: 1.5rem 1rem;
  border-bottom: 0.1rem solid #cccccc;
}
.list-modal li:last-child {
  border-bottom: none;
}
.list-modal__link--active {
  text-decoration: none;
  color: #bb5949;
  font-weight: bold;
}
.list-modal__link--active:hover {
  color: #bb5949;
}
.list-modal__link--active i {
  color: #bb5949;
  margin-left: 1rem;
  display: inline;
}
.list-modal__link--inactive {
  text-decoration: none;
  color: #1E292B;
}
.list-modal__link--inactive:hover {
  color: #7fa2a0;
}
.list-modal__link--inactive i {
  display: none;
}
.list-modal__checkbox--active {
  color: #bb5949;
}

.loader__spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #F7DDD9;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal--visible {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media only screen and (min-width: 550px) {
  .modal--visible {
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.modal--invisible {
  display: none;
}
.modal__content {
  margin: 1rem auto;
  width: min(90%, 30rem);
  background-color: #fefefe;
  border-radius: 0.5rem;
  padding: 1rem;
}
@media only screen and (min-width: 550px) {
  .modal__content {
    margin: 1rem 1rem;
  }
}
.modal__content--withFX {
  animation-name: slide-in-bottom;
  animation-duration: 0.4s;
}
.modal__content--noFX {
  animation-name: none;
  animation-duration: none;
}
.modal__header {
  display: flex;
  justify-content: flex-end;
}
.modal__icon-btn {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: #999999;
  color: white;
  padding: 0rem;
  border: 0rem;
}
.modal__body {
  display: flex;
  margin: 0rem 1rem;
}
.modal__check-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  color: #3c7f7c;
}
.modal__image {
  width: 8rem;
}
.modal__image img {
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.modal__detail {
  margin: 0rem 1.5rem;
}
.modal__product-header {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.modal__product-price {
  font-family: "Fenix", serif;
  font-size: 2rem;
  color: #3c7f7c;
  margin: 0.5rem 0rem;
}
.modal__footer {
  margin: 1rem;
  display: flex;
  justify-content: center;
}
.modal__view-cart-btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1rem;
  width: 100%;
  text-align: center;
}
.modal__view-cart-btn:active {
  background-color: #c87b6f;
}

.radioset__option--active {
  color: #1E292B;
  cursor: pointer;
}
.radioset__option--inactive {
  color: gray;
  cursor: default;
}

.subtotals {
  margin: 1rem 2rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 0.1rem solid #EDEDED;
  border-radius: 0.51rem;
  box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 600px) {
  .subtotals {
    margin: 0 2rem;
  }
}
.subtotals__header {
  margin: 1rem 0;
  padding-bottom: 1rem;
}
.subtotals__info {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}
.subtotals__price {
  font-family: "Fenix", serif;
  font-size: 2.4rem;
  color: #3c7f7c;
}
.subtotals__button {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  margin: 1rem 0;
}
.subtotals__button:active {
  background-color: #c87b6f;
}
.subtotals__delivery {
  margin: 1rem 0;
  color: #486267;
  font-size: 1.6rem;
}

.pages-shop {
  margin: 5rem 1rem;
  display: flex;
}
@media only screen and (min-width: 700px) {
  .pages-shop {
    margin: 2rem auto;
    width: min(80%, 100rem);
  }
}
.pages-shop .filters {
  display: none;
  margin-right: 5rem;
}
@media (min-width: 900px) {
  .pages-shop .filters {
    display: block;
    width: 20rem;
    margin-right: none;
  }
}
.pages-shop .filters li {
  line-height: 3rem;
}
.pages-shop .filters__header {
  padding: 1rem 0;
  color: #4F6C6B;
}
.pages-shop .filters__categories {
  padding-top: 1rem;
}
.pages-shop .filters__categories i {
  display: none;
}
.pages-shop .filters__link--active {
  text-decoration: none;
  color: #bb5949;
  font-weight: bold;
}
.pages-shop .filters__link--active:hover {
  color: #d69c94;
}
.pages-shop .filters__link--active i {
  color: #bb5949;
  margin-left: 1rem;
  display: inline;
}
.pages-shop .filters__link--inactive {
  text-decoration: none;
  color: #1E292B;
}
.pages-shop .filters__link--inactive:hover {
  color: #7fa2a0;
}
.pages-shop .filters__option.filters--active {
  color: #bb5949;
}
.pages-shop .content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.pages-shop .content__line {
  margin: 1rem 0;
  width: 100%;
  height: 0.2rem;
  background-color: #cccccc;
}
.pages-shop .content .control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pages-shop .content .control__sort {
  display: none;
}
@media (min-width: 900px) {
  .pages-shop .content .control__sort {
    display: flex;
  }
}
.pages-shop .content .compact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pages-shop .content .compact__left {
  display: flex;
}
.pages-shop .content .compact__button {
  margin-right: 1rem;
}
.pages-shop .content .compact__sort-btn {
  font-size: 2.6rem;
  margin: 0;
}
@media (min-width: 900px) {
  .pages-shop .content .compact {
    display: none;
  }
}

.product {
  margin: 5rem auto;
  width: min(90vw, 30rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (min-width: 550px) {
  .product {
    width: min(90vw, 80rem);
  }
}
.product__nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.product__nav-button {
  margin-right: 1rem;
}
.product__body {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 550px) {
  .product__body {
    flex-direction: row;
  }
}
.product__preview {
  min-width: 50%;
  margin-right: 0;
}
@media only screen and (min-width: 550px) {
  .product__preview {
    margin-right: 2rem;
  }
}
.product__preview img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}
.product__details {
  display: flex;
  flex-flow: column;
  margin-top: 2rem;
}
@media only screen and (min-width: 550px) {
  .product__details {
    margin-top: auto;
  }
}
.product__price {
  margin: 1.5rem 0rem;
  font-family: "Fenix", serif;
  font-size: 2.8rem;
  color: #3c7f7c;
}
.product__cart {
  display: flex;
}
.product__cart-btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  margin-left: 2rem;
}
.product__cart-btn:active {
  background-color: #c87b6f;
}
.product__quantity {
  display: inline;
}
.product__desc {
  margin: 2rem 0rem;
}
.product__desc h2 {
  margin: 1rem 0rem;
}
.product__desc p {
  white-space: pre-wrap;
}
.product .care-list {
  margin: 2rem 0rem;
}
.product .care-list__item {
  margin: 1.5rem 0rem;
}
.product .care-list__type-header {
  margin: 1rem 0rem;
  font-size: 1.6rem;
}
.product .care-list__icon-group {
  margin: 0rem 1rem 0rem 1rem;
  color: #4F6C6B;
}
.product .care-list__type-desc {
  font-size: 1.6rem;
  color: black;
}

.pages-cart {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  flex-direction: column;
  width: min(100vw, 360px);
}
@media only screen and (min-width: 600px) {
  .pages-cart {
    margin: 5rem auto;
    width: min(90vw, 100rem);
    flex-direction: row;
  }
}
@media only screen and (min-width: 600px) {
  .pages-cart .main--default {
    width: min(50%, 35rem);
  }
}
@media only screen and (min-width: 600px) {
  .pages-cart .title {
    display: flex;
  }
}
.pages-cart .title__label {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 600px) {
  .pages-cart .title__label {
    justify-content: flex-start;
    align-items: center;
  }
}
.pages-cart .title__count {
  margin: 2rem 1rem;
  color: #486267;
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .pages-cart .title__count {
    text-align: left;
    margin: 1rem 2rem;
  }
}
.pages-cart .list {
  display: flex;
  flex-flow: column;
  margin: 1.5rem 0rem;
}
.pages-cart .list__row {
  border-bottom: solid 0.2rem #e6e6e6;
  padding: 0rem 1rem 3rem;
}
.pages-cart .list__row:first-child {
  border-top: solid 0.2rem #e6e6e6;
}
.pages-cart .list__header {
  display: flex;
  justify-content: flex-end;
}
.pages-cart .list__header button {
  background-color: #999999;
  color: white;
  width: 3rem;
  height: 3rem;
}
.pages-cart .list__header button:hover {
  background-color: #7fa2a0;
}
.pages-cart .list__content {
  display: flex;
}
.pages-cart .list__preview {
  display: flex;
}
.pages-cart .list__preview a {
  display: flex;
}
.pages-cart .list__preview img {
  width: min(100px, 30vw);
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.5rem;
}
.pages-cart .list__detail {
  margin-left: 2rem;
}
.pages-cart .list__product-label {
  margin: 1rem 0rem 2rem;
}
.pages-cart .list__product-label h2 {
  font-size: 2rem;
}
.pages-cart .list__quantity-control {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}
.pages-cart .list__subtotal {
  margin: 0rem 0.5rem;
  font-family: "Fenix", serif;
  font-size: 2.4rem;
  color: #3c7f7c;
}
.pages-cart .empty-list {
  margin: 1rem;
  padding: 5rem min(15rem, 15vw);
  border: 0.3rem dashed #cf8b81;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pages-cart .empty-list img {
  width: 10rem;
  color: #F7DDD9;
  margin: 1rem 0;
  height: auto;
}
.pages-cart .empty-list h1 {
  text-align: center;
  color: #1E292B;
  font-family: "Stoke", serif;
}
.pages-cart .empty-list > div {
  text-align: center;
  color: #4F6C6B;
}
.pages-cart .empty-list__detail {
  margin: 2rem 0rem;
}
.pages-cart .empty-list__shop-btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
}
.pages-cart .empty-list__shop-btn:active {
  background-color: #c87b6f;
}
@media only screen and (min-width: 600px) {
  .pages-cart .subtotals {
    align-self: flex-start;
  }
}

.pages-checkout {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
  flex-direction: column;
  width: min(100vw, 360px);
}
@media only screen and (min-width: 600px) {
  .pages-checkout {
    margin: 5rem auto;
    width: min(90vw, 100rem);
    flex-direction: row;
    align-items: flex-start;
  }
}
.pages-checkout .cart-summary {
  margin: 0 1rem 4rem;
  padding: 2rem;
  border: 0.1rem solid #EDEDED;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 600px) {
  .pages-checkout .cart-summary {
    width: min(30vw, 35rem);
    margin: 0 2rem;
  }
}
.pages-checkout .cart-summary__header {
  display: flex;
  align-items: baseline;
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid gray;
}
.pages-checkout .cart-summary__cart-count {
  margin: 0rem 1rem;
}
.pages-checkout .cart-summary__body {
  margin: 2rem 5%;
}
.pages-checkout .cart-summary__list {
  font-weight: 700;
}
.pages-checkout .cart-summary__list > div {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;
}
.pages-checkout .cart-summary__list > div:last-child {
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #cccccc;
}
.pages-checkout .cart-summary__price {
  font-family: "Fenix", serif;
  font-size: 2rem;
  color: #3c7f7c;
  font-weight: 400;
}
.pages-checkout .cart-summary__delivery-info {
  color: #bb5949;
  font-weight: 400;
}
.pages-checkout .cart-summary__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0rem;
}
.pages-checkout .cart-summary__total-price {
  font-family: "Fenix", serif;
  font-size: 3rem;
  color: #3c7f7c;
  font-weight: 400;
}
.pages-checkout .billing {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}
.pages-checkout .billing__header {
  padding-bottom: 1rem;
}
.pages-checkout .billing__subheader {
  padding: 1rem 0;
  font-size: 1.6rem;
}
@media only screen and (min-width: 600px) {
  .pages-checkout .billing__form {
    width: min(40vw, 40rem);
  }
}
.pages-checkout .billing__full {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.pages-checkout .billing__group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.pages-checkout .billing__group label {
  margin: 1rem 0;
  font-weight: 700;
}
.pages-checkout .billing__field {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
}
.pages-checkout .billing__field--default {
  border: 0.1rem solid #b3b3b3;
}
.pages-checkout .billing__field--error {
  border: 0.1rem solid #bb5949;
}
.pages-checkout .billing__field-error-info {
  color: #bb5949;
  margin-bottom: 2rem;
}
.pages-checkout .billing__footer {
  display: flex;
}
.pages-checkout .billing__button {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  margin: 2rem 0rem;
}
.pages-checkout .billing__button:active {
  background-color: #c87b6f;
}
.pages-checkout .billing .preview {
  border-bottom: 0.1rem solid #cccccc;
  border-radius: 0.2rem;
}
.pages-checkout .billing .preview__header {
  display: flex;
  align-items: center;
}
.pages-checkout .billing .preview__header h3 {
  font-size: 1.6rem;
}
.pages-checkout .billing .preview__info {
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
}
.pages-checkout .billing .preview__info i {
  color: #4F6C6B;
  margin-right: 1rem;
}
.pages-checkout .billing .preview__info-label {
  font-weight: 700;
}
.pages-checkout .billing .preview__info-body {
  color: gray;
}
.pages-checkout .billing .preview__edit {
  width: 2.4rem;
  height: 2.4rem;
  color: #FFFFFF;
}
.pages-checkout .billing .preview__edit--enabled {
  background-color: #bb5949;
  cursor: none;
}
.pages-checkout .billing .preview__edit--enabled:active {
  background-color: #e6e6e6;
}
.pages-checkout .billing .preview__edit--disabled {
  background-color: gray;
  cursor: none;
}
.pages-checkout .billing .preview__edit--disabled:active {
  background-color: gray;
}
.pages-checkout .billing .payment {
  margin: 1rem 0rem;
}
.pages-checkout .billing .payment__header {
  margin: 1rem 0rem;
}
.pages-checkout .billing .payment__header h3 {
  font-size: 1.6rem;
}
.pages-checkout .billing .payment__desc {
  margin: 1rem 0rem;
}
.pages-checkout .billing .payment__footer {
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem;
}
.pages-checkout .billing .payment__footer .loader {
  margin: 0 auto;
}
.pages-checkout .billing .payment__btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  align-self: flex-start;
}
.pages-checkout .billing .payment__btn:active {
  background-color: #c87b6f;
}

.pages-contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5rem auto;
  width: min(100vw, 360px);
}
@media only screen and (min-width: 600px) {
  .pages-contact {
    margin: 5rem auto;
    width: min(90vw, 100rem);
    flex-direction: row;
    align-items: flex-start;
  }
}
.pages-contact .page-info {
  margin: 0 1rem 4rem;
  padding: 2rem;
  border: 0.1rem solid #EDEDED;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
}
.pages-contact .page-info p {
  margin: 2rem auto;
}
@media only screen and (min-width: 600px) {
  .pages-contact .page-info {
    width: min(30vw, 35rem);
    margin: 0 2rem;
  }
}
.pages-contact .message-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 1rem;
}
@media only screen and (min-width: 600px) {
  .pages-contact .message-form {
    width: min(40vw, 40rem);
  }
}
.pages-contact .message-form label {
  margin: 1rem 0;
  font-weight: 700;
}
.pages-contact .message-form__field {
  padding: 0.5rem 0.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  border: 0.1rem solid #b3b3b3;
  border-radius: 0.2rem;
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
}
.pages-contact .message-form__field--default {
  border: 0.1rem solid #b3b3b3;
}
.pages-contact .message-form__field--error {
  border: 0.1rem solid #bb5949;
}
.pages-contact .message-form__field-error-info {
  color: #bb5949;
  margin-bottom: 2rem;
}
.pages-contact .message-form__textarea {
  padding: 0.5rem 0.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  border: 0.1rem solid #b3b3b3;
  border-radius: 0.2rem;
  font-family: "Nunito", sans-serif;
  font-size: 1.6rem;
  height: auto;
}
.pages-contact .message-form__textarea--default {
  border: 0.1rem solid #b3b3b3;
}
.pages-contact .message-form__textarea--error {
  border: 0.1rem solid #bb5949;
}
.pages-contact .message-form__btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  align-self: flex-start;
  margin: 2rem 0;
}
.pages-contact .message-form__btn:active {
  background-color: #c87b6f;
}

.pages-faq {
  margin: 5rem auto;
  width: min(90vw, 30rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (min-width: 550px) {
  .pages-faq {
    width: min(90vw, 60rem);
  }
}
.pages-faq .header {
  margin: 2rem 0;
}
.pages-faq .collapse-list {
  width: 100%;
}
.pages-faq .collapse-list .list-item {
  border-top: 0.1rem solid #b3b3b3;
  padding: 0.5rem;
  cursor: pointer;
}
.pages-faq .collapse-list .list-item:last-child {
  border-bottom: 0.1rem solid #b3b3b3;
}
.pages-faq .collapse-list .list-item__preview {
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 1rem 0;
}
.pages-faq .collapse-list .list-item__preview i {
  text-align: center;
}
.pages-faq .collapse-list .list-item__title {
  margin-right: 1rem;
  font-size: 1.6rem;
}
.pages-faq .collapse-list .list-item__content {
  padding: 1rem 0;
  max-width: 90%;
}
.pages-faq .collapse-list .list-item--active .list-item__title {
  color: #7fa2a0;
}
.pages-faq .collapse-list .list-item--active .list-item__content {
  display: block;
}
.pages-faq .collapse-list .list-item--inactive .list-item__title {
  color: #1E292B;
}
.pages-faq .collapse-list .list-item--inactive .list-item__content {
  display: none;
}
.pages-faq details > summary {
  list-style-type: none;
}

.pages-notfound {
  margin: 5rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.pages-notfound .notfound {
  padding: 5rem 5rem;
  width: min(80vw, 80rem);
  border: 0.3rem dashed #cf8b81;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pages-notfound .notfound img {
  width: 10rem;
  color: #F7DDD9;
  margin: 1rem 0;
}
.pages-notfound .notfound h1 {
  text-align: center;
  color: #1E292B;
  font-family: "Stoke", serif;
}
.pages-notfound .notfound div {
  text-align: center;
  color: #4F6C6B;
}
.pages-notfound .notfound__detail {
  margin: 2rem 0rem;
}
.pages-notfound .notfound__home-btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
}
.pages-notfound .notfound__home-btn:active {
  background-color: #c87b6f;
}

.pages-sent {
  margin: 5rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.pages-sent .sent {
  padding: 5rem 5rem;
  width: min(80vw, 80rem);
  border: 0.3rem dashed #cf8b81;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pages-sent .sent img {
  width: 10rem;
  color: #F7DDD9;
  margin: 1rem 0;
  height: auto;
}
.pages-sent .sent h1 {
  text-align: center;
  color: #1E292B;
  font-family: "Stoke", serif;
}
.pages-sent .sent div {
  text-align: center;
  color: #4F6C6B;
}
.pages-sent .sent__detail {
  margin: 2rem 0rem;
}
.pages-sent .sent__home-btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
}
.pages-sent .sent__home-btn:active {
  background-color: #c87b6f;
}

.pages-thanks {
  margin: 5rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.pages-thanks .thanks {
  padding: 5rem 5rem;
  width: min(80vw, 80rem);
  border: 0.3rem dashed #cf8b81;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pages-thanks .thanks img {
  width: 10rem;
  height: auto;
  color: #F7DDD9;
  margin: 1rem 0;
}
.pages-thanks .thanks h1 {
  text-align: center;
  color: #1E292B;
  font-family: "Stoke", serif;
}
.pages-thanks .thanks div {
  text-align: center;
  color: #4F6C6B;
}
.pages-thanks .thanks__detail {
  margin: 2rem 0rem;
}
.pages-thanks .thanks__home-btn {
  font-weight: 400;
  font-size: large;
  background-color: #bb5949;
  text-transform: uppercase;
  color: white;
  border: 0rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
}
.pages-thanks .thanks__home-btn:active {
  background-color: #c87b6f;
}/*# sourceMappingURL=app.css.map */