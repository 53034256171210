.banner {
  display: flex;
  flex-direction: column;
  background-color: $color1;

  @media only screen and (min-width:600px) {
    border-radius: 1rem;
    margin: 2rem auto;
    width: calc(min(90%, 140rem));
    flex-direction: row-reverse;
  }

  .image{
    flex: 2;

    img {
      display: block; //https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
      height: 100%;
      width: 100%;
      object-fit: cover;
      background-color: lightgray;

      @media only screen and (min-width:600px) {
        border-radius: 0 1rem 1rem 0;
      }
    }
  }

  .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5% 5%;
    flex: 1;

    &__line{
      height: 0.5rem;
      width: 100%;
      color : $color2;
      background-color: lighten($color3, 30%);
      border: none;
      margin: 1rem 0;
    }

    &__text{
      margin: 1rem 0;
    }

    &__button{
      @include textbutton;
      margin-top: 10%;
    }
  }
}
