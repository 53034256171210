.features {
  margin: 1rem 0;
  border: 0.1rem solid $color1;

  @media(min-width:400px) {
    margin: 5rem auto;
    max-width: 40rem;
    border-radius: 1rem;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
  }
  
  @media(min-width:750px) {
    margin: 5rem auto;
    width: calc(min(90%, 100rem));
    max-width: 80rem;
  }

  &__list {
    display: grid;
    grid: repeat(2, 1fr)/ repeat(2 ,1fr);
    margin: 0 2rem;
    grid-gap: 1rem;

    @media(min-width:750px) {
      grid: auto-flow / repeat(4, 1fr);
    }
  }

  &__icon{
    display: block;
    margin: 10% auto;
    width: 60%;
    height: auto;
  }


  &__label{
    text-align: center;
    white-space: pre-line;
    color: $color4;
    margin: 0 auto;
    font-size: 1.6rem;
  }

  &__line{
    height: 0.3rem;
    color : $color2;
    background-color: $color2;
    border: none;
    width: 80%;
    margin: 1rem auto;
  }

  &__desc{
    display: block;
    margin: 1rem auto;
    text-align: center;
    color: $subtext;
    font-size: 1.4rem;
    padding: 5%;
  }
}